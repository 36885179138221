<template>
  <base-layout>
    <ion-content class="has-header has-subheader" id="wcpt-sessiontypes">
      <div class="content">
        <div class="sessiontype-wrapper">
          <template  v-for="tag in tags"  :key="tag.id">
            <router-link
                class="sessiontype-item"
                :to="'/app/tags/' + tag.id"
                :style="{'background-color': tag.color, 'color': tag.font_color}"
                v-if="tag.title && tag.id != 'da7ad0c0-3ed1-4500-1391-0c0000000286'  && tag.count > 0"
            > 
              <span class="text" :style="{'color': tag.font_color}">{{ tag.title || 'Other' }}</span>
              <span class="count" :style="{'color': tag.font_color}">{{ tag.count }}</span>
            </router-link>

          </template>

        </div>
      </div>
    </ion-content>
  </base-layout>
</template>
<script>
import {IonContent} from "@ionic/vue";
import {mapActions} from "vuex";

export default {
  data() {
    return {
      tags: [],
    };
  },
  components: {
    IonContent,
  },
  methods: {
    ...mapActions("sessiontypes", ["getTags"]),
    ...mapActions('googleanalytics', ['trackWithLabel']),
  },
  async created() {
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(route) {
        if (route.name == 'Tag List') {
          this.tags = await this.getTags();
          console.log('tags', this.tags)
          this.trackWithLabel('TAGS')
        }
      }
    }
  }
};
</script>
<style scoped>
#type-detail-page {
  --padding-top: 10px;
  --padding-bottom: 10px;
}
</style>

